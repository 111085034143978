// Imports
// ------
import { css } from 'styled-components';
import { breakup, breakonly } from '@tackl';
import { isDefined } from './utils';

// Exports
// ------
const pushPoints = [
	{ propInput: 'Small', breakpoint: 'small' },
	{ propInput: 'Smedium', breakpoint: 'smedium' },
	{ propInput: 'Medium', breakpoint: 'medium' },
	{ propInput: 'Large', breakpoint: 'large' },
	{ propInput: 'Xlarge', breakpoint: 'xlarge' },
	{ propInput: 'Xxlarge', breakpoint: 'xxlarge' },
	{ propInput: 'Huge', breakpoint: 'huge' },
	{ propInput: 'Uber', breakpoint: 'uber' },
];

export const push = pushPoints.map((item) => {
	const input = `push${item.propInput}`;

	return (props) =>
		isDefined(props[input]) &&
		css`
			${breakup[item.breakpoint]`
            position: relative;
            left: calc(100% / 12 * ${props[input]});
        `}
		`;
});

export const pushOnly = pushPoints.map((item) => {
	const input = `push${item.propInput}Only`;

	return (props) =>
		isDefined(props[input]) &&
		css`
			${breakonly[item.breakpoint]`
            position: relative;
            left: calc(100% / 12 * ${props[input]});
        `}
		`;
});

export const pull = pushPoints.map((item) => {
	const input = `pull${item.propInput}`;

	return (props) =>
		isDefined(props[input]) &&
		css`
			${breakup[item.breakpoint]`
            position: relative;
            left: calc(-100% / 12 * ${props[input]});
        `}
		`;
});

export const pullOnly = pushPoints.map((item) => {
	const input = `pull${item.propInput}Only`;

	return (props) =>
		isDefined(props[input]) &&
		css`
			${breakonly[item.breakpoint]`
            position: relative;
            left: calc(-100% / 12 * ${props[input]});
        `}
		`;
});

export default push;
