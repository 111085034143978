// Imports
// ------
import { css } from 'styled-components';
import { breakup } from '@tackl';

// Exports
// ------
const visibility = (props) => css`
	${props.showSmedium &&
	css`
		display: none !important;
		${breakup.smedium` display: flex !important; `}
	`}

	${props.showMedium &&
	css`
		display: none !important;
		${breakup.medium` display: flex !important; `}
	`}

	${props.showLarge &&
	css`
		display: none !important;
		${breakup.large` display: flex !important; `}
	`}

	${props.showXlarge &&
	css`
		display: none !important;
		${breakup.xlarge` display: flex !important; `}
	`}

	${props.showXxlarge &&
	css`
		display: none !important;
		${breakup.xxlarge` display: flex !important; `}
	`}
	
	${props.showHuge &&
	css`
		display: none !important;
		${breakup.huge` display: flex !important; `}
	`}

	${props.showUber &&
	css`
		display: none !important;
		${breakup.uber` display: flex !important; `}
	`}
`;

export default visibility;
