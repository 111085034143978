// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';

export const Jacket = styled.svg(
	({ theme, isOpen }) => css`
		display: block;
		fill: ${theme.colors.brand.bc1};

		width: 156px;
		height: 48px;

		transition: opacity 0.3s ease-in-out;
		transition-delay: 0.4s;

		${isOpen &&
		css`
			opacity: 0;
			transition-delay: 0s;
		`}

		${breakup.large`
        width: 196px;
		height: 60px;
    `}
	`
);
