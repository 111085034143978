// Imports
// ------
import { css } from 'styled-components';
import { breakup } from '@tackl';

// Exports
// ------
const offset = (props) => css`
	${props.offsetSmall &&
	css`
		margin-left: calc(100% / 12 * ${props.offsetSmall});
	`}

	${props.offsetSmedium &&
	css`
		${breakup.smedium`
            margin-left: calc(100% / 12 * ${props.offsetSmedium});
        `}
	`}

    ${props.offsetMedium &&
	css`
		${breakup.medium`
            margin-left: calc(100% / 12 * ${props.offsetMedium});
        `}
	`}
    
    ${props.offsetLarge &&
	css`
		${breakup.large`
            margin-left: calc(100% / 12 * ${props.offsetLarge});
        `}
	`}

    ${props.offsetXLarge &&
	css`
		${breakup.xlarge`
            margin-left: calc(100% / 12 * ${props.offsetXLarge});
        `}
	`}

    ${props.offsetXLarge &&
	css`
		${breakup.xlarge`
            margin-left: calc(100% / 12 * ${props.offsetXLarge});
        `}
	`}

    ${props.offsetXXLarge &&
	css`
		${breakup.xxlarge`
            margin-left: calc(100% / 12 * ${props.offsetXXLarge});
        `}
	`}

    ${props.offsetHuge &&
	css`
		${breakup.huge`
            margin-left: calc(100% / 12 * ${props.offsetHuge});
        `}
	`}

    ${props.offsetUber &&
	css`
		${breakup.uber`
            margin-left: calc(100% / 12 * ${props.offsetUber});
        `}
	`}
`;

export default offset;
