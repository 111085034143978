// Imports
// ------------
import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { siteReady } from '@states';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import gsap from 'gsap';

// Styles
// ------------
import {
	Jacket,
	AnimatedPattern,
	Content,
	PatternBG,
	Pattern,
	Text,
	Loading,
} from './styles';

// Component
// ------------
const Loader = () => {
	// NOTE • States
	const [playAnimation, setPlayAnimation] = useState(false);

	// NOTE • Refs
	const main = useRef();
	const percent = useRef();

	// NOTE • Do the stuff when loaded
	useEffect(() => {
		const onPageLoad = () => {
			setPlayAnimation(true);

			const activate = action(() => {
				siteReady.isReady = true;
			});

			const readyTheSite = setTimeout(() => {
				activate();
			}, 4000);

			clearTimeout(() => readyTheSite);
		};

		// Check if the page has already loaded
		if (document.readyState === 'complete') {
			onPageLoad();
		} else {
			window.addEventListener('load', onPageLoad);
			// Remove the event listener when component unmounts
			return () => window.removeEventListener('load', onPageLoad);
		}
	}, []);

	useEffect(() => {
		const ctx = gsap.context(() => {
			gsap.to(percent.current, {
				textContent: 100,
				duration: 4,
				ease: 'power1.in',
				snap: { textContent: 1 },
			});
		}, main);

		return () => ctx.revert();
	}, []);

	return (
		<Jacket isDone={siteReady.isReady} ref={main}>
			<Pattern>
				<PatternBG>
					<span />
					<span />
					<span />
					<span />
				</PatternBG>

				<AnimatedPattern play={playAnimation}>
					<span />
					<span />
					<span />
					<span />
				</AnimatedPattern>
			</Pattern>

			<Content>
				<Text>Welcome to MONOSTATE</Text>
				<Loading>
					LOADING <span ref={percent}>0</span>%
				</Loading>
			</Content>
		</Jacket>
	);
};

export default observer(Loader);
