// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Em, H5 } from '@tackl';
// Exports
// ------------
export const Jacket = styled.header(
	(props) => css`
		position: fixed;
		z-index: 99;
		top: 0;
		left: 50%;
		transform: translateX(-50%);

		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;

		width: 42rem;
		padding: 0rem 1.2rem 4.3rem;
		background: ${props.theme.colors.brand.bc250};
		border-radius: 0 0 0.6rem 0.6rem;
		backdrop-filter: blur(3.6rem);
		overflow: hidden;

		clip-path: inset(
			${props.isOpen ? `-0.6rem 0rem 0rem 0rem` : `-0.6rem 11rem 33rem 11rem`}
				round 0.6rem
		);
		transform: translateY(${props.hide ? -100 : 0}%) translateX(-50%);
		transition: all 1s ${props.theme.easing.bezzy};

		${!props.hasInsta &&
		css`
			clip-path: inset(
				${props.isOpen
						? `-0.6rem 0rem 0rem 0rem`
						: `-0.6rem 11rem 22.5rem 11rem`}
					round 0.6rem
			);
		`}

		${breakup.large`
        width: 47rem;
        padding: 0rem 1.2rem 4rem;

        clip-path: inset(${
					props.isOpen ? `-0.6rem 0rem 0rem 0rem` : `-0.6rem 11rem 33rem 11rem`
				} round 0.6rem);

        ${
					!props.hasInsta &&
					css`
						clip-path: inset(
							${props.isOpen
									? `-0.6rem 0rem 0rem 0rem`
									: `-0.6rem 11rem 22.5rem 11rem`}
								round 0.6rem
						);
					`
				}
    `}

    &:before {
			content: '';
			position: absolute;
			top: 0.6rem;
			bottom: -2px;
			left: -2px;
			right: -2px;
			overflow: hidden;
			background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='${props
				.theme.colors.brand
				.bc325}' stroke-width='2' stroke-dasharray='6%2c4' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
			pointer-events: none;
			user-select: none;
		}
	`
);

export const Animation = styled.div(
	(props) => css`
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		gap: 2.4rem;

		opacity: ${props.isOpen ? 1 : 0};
		transform: scale(${props.isOpen ? 1 : 0.5});
		transform-origin: top center;
		transition: all 1s ${props.theme.easing.bezzy};
	`
);

export const Close = styled.div(
	(props) => css`
		position: absolute;
		z-index: 2;
		top: 1.7rem;
		left: 50%;
		transform: translateX(-50%);

		display: block;
		fill: ${props.theme.colors.brand.bc1};

		width: 4.9rem;
		height: 4.9rem;

		border-radius: 6rem;

		${breakup.large`
        width: 6.1rem;
        height: 6.1rem;
    `}

		span {
			position: absolute;
			top: 50%;
			left: 50%;

			width: 3.6rem;
			height: 2px;
			background: ${props.theme.colors.brand.bc1};
			transition: transform 1s ${props.theme.easing.bezzy};
		}
	`
);

export const Clicker = styled.button(
	(props) => css`
		position: relative;
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		background: transparent;
		gap: 0.6rem;
		padding: 1.8rem 1.2rem;
		transition: ${props.theme.easing.ease};

		${breakup.large`
        &:hover {
            opacity: 0.6;
        }
    `}

		.letter {
			opacity: ${props.isOpen ? 0 : 1};
			transition: ${props.theme.easing.ease};
			transition-delay: ${props.isOpen ? 0 : 0.5}s;
		}

		${Close} {
			span {
				&:nth-child(1) {
					transform: translate(-50%, -50%) rotate(${props.isOpen ? 45 : 90}deg)
						scaleX(${props.isOpen ? 1 : 0});
				}

				&:nth-child(2) {
					transform: translate(-50%, -50%)
						rotate(${props.isOpen ? -45 : 180}deg)
						scaleX(${props.isOpen ? 1 : 0});
				}
			}
		}
	`
);

const sharedTextStyles = (props) => css`
	color: ${props.theme.colors.brand.bc1};
	text-align: center;
`;

export const Label = styled(Em)(
	(props) => css`
		${sharedTextStyles}
	`
);

export const Heading = styled(H5)(
	(props) => css`
		${sharedTextStyles}
	`
);

export const Text = styled(Em)(
	(props) => css`
		${sharedTextStyles}

		a {
			display: inline-block;

			${breakup.large`
            transition: ${props.theme.easing.ease};

            &:hover {
                opacity: 0.5;
            }
        `}
		}
	`
);

export const EmailNotifications = styled.div(
	(props) => css`
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		gap: 0.6rem;
	`
);

export const Instagram = styled.div(
	(props) => css`
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		gap: 0.6rem;
		padding: 2.4rem 0 1.2rem;

		${props.showThis &&
		css`
			display: none;
		`}
	`
);
