// Imports
// ------------
import React from 'react';

// Styles
// ------------
import { Jacket } from './styles';

// Component
// ------------
const Logo = ({ className, isOpen }) => (
	<Jacket
		width='196'
		height='16'
		viewBox='0 0 196 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		className={className}
		isOpen={isOpen}>
		<path
			d='M0.5 15.4143V0.585938H2.74543L9.01569 9.10163H8.10481L14.3962 0.585938H16.5993V15.4143H14.3115V9.20755C14.3115 7.99304 14.3398 6.87738 14.3962 5.86058C14.4527 4.82966 14.5657 3.79168 14.7352 2.74664L14.9894 3.57279L9.22752 11.1564H7.82942L2.13111 3.61515L2.36413 2.74664C2.51948 3.76343 2.62539 4.78729 2.68188 5.81822C2.75249 6.83501 2.7878 7.96479 2.7878 9.20755V15.4143H0.5Z'
			fill='#0600FF'
		/>
		<path
			d='M32.9986 15.626C31.7841 15.626 30.6684 15.4354 29.6516 15.0541C28.6489 14.6586 27.7663 14.1149 27.0037 13.4229C26.2552 12.731 25.6762 11.926 25.2667 11.008C24.8571 10.076 24.6523 9.0733 24.6523 8.00001C24.6523 6.92673 24.8571 5.93111 25.2667 5.01317C25.6762 4.0811 26.2552 3.26908 27.0037 2.57709C27.7663 1.8851 28.6489 1.34846 29.6516 0.967156C30.6684 0.571734 31.7841 0.374023 32.9986 0.374023C34.2131 0.374023 35.3217 0.564673 36.3243 0.945973C37.3411 1.32727 38.2238 1.87098 38.9723 2.57709C39.7349 3.26908 40.3209 4.0811 40.7305 5.01317C41.1541 5.93111 41.366 6.92673 41.366 8.00001C41.366 9.0733 41.1541 10.076 40.7305 11.008C40.3209 11.926 39.7349 12.738 38.9723 13.4441C38.2238 14.1361 37.3411 14.6728 36.3243 15.0541C35.3217 15.4354 34.2131 15.626 32.9986 15.626ZM32.9986 13.4018C33.8883 13.4018 34.6932 13.2676 35.4135 12.9993C36.1478 12.7168 36.7833 12.3355 37.32 11.8554C37.8707 11.3611 38.2944 10.7892 38.591 10.1395C38.8875 9.47579 39.0358 8.76261 39.0358 8.00001C39.0358 7.23742 38.8875 6.5313 38.591 5.88168C38.2944 5.21794 37.8707 4.64599 37.32 4.16584C36.7833 3.67156 36.1478 3.29026 35.4135 3.02194C34.6932 2.73949 33.8883 2.59827 32.9986 2.59827C32.123 2.59827 31.318 2.73949 30.5837 3.02194C29.8493 3.29026 29.2068 3.6645 28.656 4.14465C28.1193 4.62481 27.7027 5.19676 27.4062 5.8605C27.1237 6.51012 26.9825 7.22329 26.9825 8.00001C26.9825 8.76261 27.1237 9.47579 27.4062 10.1395C27.7027 10.8033 28.1193 11.3752 28.656 11.8554C29.2068 12.3355 29.8493 12.7168 30.5837 12.9993C31.318 13.2676 32.123 13.4018 32.9986 13.4018Z'
			fill='#0600FF'
		/>
		<path
			d='M49.418 15.4143V0.585938H51.5999L62.5728 12.724L62.361 12.9146C62.3045 12.5192 62.248 12.0885 62.1915 11.6224C62.1491 11.1564 62.1138 10.6763 62.0856 10.182C62.0573 9.6877 62.0291 9.1793 62.0009 8.65678C61.9867 8.12014 61.9726 7.58349 61.9585 7.04685C61.9444 6.5102 61.9373 5.98062 61.9373 5.4581V0.585938H64.2251V15.4143H62.0009L51.1762 3.72107L51.2821 3.4245C51.3245 3.89053 51.3598 4.34951 51.388 4.80142C51.4304 5.2392 51.4728 5.66287 51.5151 6.07242C51.5575 6.48196 51.5857 6.87738 51.5999 7.25868C51.6281 7.62586 51.6493 7.97891 51.6634 8.31785C51.6916 8.64266 51.7058 8.95335 51.7058 9.24991V15.4143H49.418Z'
			fill='#0600FF'
		/>
		<path
			d='M80.6275 15.626C79.413 15.626 78.2973 15.4354 77.2805 15.0541C76.2778 14.6586 75.3952 14.1149 74.6326 13.4229C73.8841 12.731 73.3051 11.926 72.8956 11.008C72.486 10.076 72.2812 9.0733 72.2812 8.00001C72.2812 6.92673 72.486 5.93111 72.8956 5.01317C73.3051 4.0811 73.8841 3.26908 74.6326 2.57709C75.3952 1.8851 76.2778 1.34846 77.2805 0.967156C78.2973 0.571734 79.413 0.374023 80.6275 0.374023C81.842 0.374023 82.9506 0.564673 83.9533 0.945973C84.9701 1.32727 85.8527 1.87098 86.6012 2.57709C87.3638 3.26908 87.9498 4.0811 88.3594 5.01317C88.7831 5.93111 88.9949 6.92673 88.9949 8.00001C88.9949 9.0733 88.7831 10.076 88.3594 11.008C87.9498 11.926 87.3638 12.738 86.6012 13.4441C85.8527 14.1361 84.9701 14.6728 83.9533 15.0541C82.9506 15.4354 81.842 15.626 80.6275 15.626ZM80.6275 13.4018C81.5172 13.4018 82.3221 13.2676 83.0424 12.9993C83.7767 12.7168 84.4122 12.3355 84.9489 11.8554C85.4996 11.3611 85.9233 10.7892 86.2199 10.1395C86.5164 9.47579 86.6647 8.76261 86.6647 8.00001C86.6647 7.23742 86.5164 6.5313 86.2199 5.88168C85.9233 5.21794 85.4996 4.64599 84.9489 4.16584C84.4122 3.67156 83.7767 3.29026 83.0424 3.02194C82.3221 2.73949 81.5172 2.59827 80.6275 2.59827C79.7519 2.59827 78.9469 2.73949 78.2126 3.02194C77.4782 3.29026 76.8357 3.6645 76.2849 4.14465C75.7483 4.62481 75.3316 5.19676 75.0351 5.8605C74.7526 6.51012 74.6114 7.22329 74.6114 8.00001C74.6114 8.76261 74.7526 9.47579 75.0351 10.1395C75.3316 10.8033 75.7483 11.3752 76.2849 11.8554C76.8357 12.3355 77.4782 12.7168 78.2126 12.9993C78.9469 13.2676 79.7519 13.4018 80.6275 13.4018Z'
			fill='#0600FF'
		/>
		<path
			d='M103.804 15.626C102.83 15.626 101.933 15.5201 101.114 15.3083C100.295 15.0964 99.5465 14.7787 98.8686 14.355C98.2049 13.9313 97.5976 13.3947 97.0469 12.7451L98.5297 10.8809C99.3629 11.8413 100.196 12.5332 101.029 12.9569C101.877 13.3665 102.837 13.5712 103.91 13.5712C104.574 13.5712 105.195 13.4865 105.774 13.317C106.368 13.1334 106.848 12.8792 107.215 12.5544C107.596 12.2155 107.787 11.7989 107.787 11.3046C107.787 10.9798 107.695 10.7044 107.511 10.4785C107.328 10.2525 107.081 10.0619 106.77 9.90651C106.459 9.75117 106.106 9.62407 105.711 9.52521C105.33 9.41224 104.934 9.32044 104.525 9.24983C104.129 9.17922 103.755 9.11567 103.402 9.05918C102.526 8.93208 101.735 8.75555 101.029 8.5296C100.337 8.28952 99.7513 7.99295 99.2711 7.6399C98.791 7.28684 98.4238 6.86318 98.1696 6.3689C97.9154 5.87462 97.7883 5.30973 97.7883 4.67424C97.7883 4.02461 97.9366 3.43148 98.2331 2.89484C98.5438 2.35819 98.9746 1.90628 99.5253 1.53911C100.076 1.15781 100.719 0.868301 101.453 0.67059C102.187 0.472879 102.985 0.374023 103.847 0.374023C104.751 0.374023 105.577 0.472879 106.325 0.67059C107.088 0.868301 107.759 1.16487 108.338 1.56029C108.931 1.94159 109.425 2.41468 109.82 2.97957L108.253 4.63187C107.9 4.15171 107.49 3.74923 107.024 3.42442C106.572 3.09961 106.071 2.85247 105.52 2.683C104.969 2.51354 104.39 2.4288 103.783 2.4288C103.091 2.4288 102.477 2.51354 101.94 2.683C101.404 2.85247 100.98 3.09255 100.669 3.40324C100.359 3.6998 100.203 4.05992 100.203 4.48359C100.203 4.83664 100.295 5.14733 100.479 5.41565C100.662 5.66985 100.923 5.88875 101.262 6.07233C101.601 6.25592 102.018 6.41127 102.512 6.53837C103.006 6.66547 103.571 6.77138 104.207 6.85612C105.026 6.98322 105.796 7.14562 106.516 7.34333C107.25 7.52692 107.893 7.77406 108.443 8.08475C108.994 8.38131 109.425 8.76261 109.736 9.22865C110.046 9.68056 110.202 10.2384 110.202 10.9021C110.202 11.8624 109.94 12.6956 109.418 13.4018C108.895 14.1079 108.154 14.6586 107.194 15.0541C106.233 15.4354 105.104 15.626 103.804 15.626Z'
			fill='#0600FF'
		/>
		<path
			d='M123.338 15.4143V2.789H118.254V0.585938H130.858V2.789H125.626V15.4143H123.338Z'
			fill='#0600FF'
		/>
		<path
			d='M138.91 15.4143L145.604 0.585938H148.167L154.819 15.4143H152.319L147.913 5.66993C147.828 5.4581 147.722 5.2039 147.595 4.90733C147.468 4.59664 147.334 4.2789 147.193 3.95408C147.066 3.62927 146.939 3.30446 146.812 2.97965C146.699 2.65484 146.6 2.36534 146.515 2.11114L147.129 2.08995C147.016 2.38652 146.896 2.69721 146.769 3.02202C146.656 3.34683 146.536 3.67164 146.409 3.99645C146.282 4.30714 146.155 4.61077 146.028 4.90733C145.901 5.2039 145.781 5.48634 145.668 5.75467L141.283 15.4143H138.91ZM141.833 11.8555L142.723 9.65239H150.773L151.366 11.8555H141.833Z'
			fill='#0600FF'
		/>
		<path
			d='M167.955 15.4143V2.789H162.871V0.585938H175.475V2.789H170.243V15.4143H167.955Z'
			fill='#0600FF'
		/>
		<path
			d='M183.531 15.4143V0.585938H194.758V2.789H185.819V13.2112H194.758V15.4143H183.531ZM184.696 8.91098V6.70792H193.593V8.91098H184.696Z'
			fill='#0600FF'
		/>
	</Jacket>
);

export default Logo;
