// Imports
// ------
import { useStaticQuery, graphql } from 'gatsby';

// Exports
// ------
export const useDato = () => {
	const data = useStaticQuery(
		graphql`
			query Header {
				page: datoCmsOptionsGlobal {
					email: partnersEmail
					instaUrl: instagramUrl
					insta: instagramLive
				}
			}
		`
	);
	return data.page;
};
