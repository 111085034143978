// Imports
// ------
import { css } from 'styled-components';
import { breakpointUp } from './breakpoints';

// Exports
// --------------
// 1. Margin
// --------------
const marginStyles = (props) => css`
	${props.marBottom &&
	css`
		margin-bottom: ${props.theme.space.small};

		${breakpointUp.medium` margin-bottom: ${props.theme.space.medium}; `}
		${breakpointUp.large` margin-bottom: ${props.theme.space.large}; `}
	`}

	${props.marTop &&
	css`
		margin-top: ${props.theme.space.small};

		${breakpointUp.medium` margin-top: ${props.theme.space.medium}; `}
		${breakpointUp.large` margin-top: ${props.theme.space.large}; `}
	`}
    
    ${props.mar &&
	css`
		margin-top: ${props.theme.space.small};
		margin-bottom: ${props.theme.space.small};

		${breakpointUp.medium`
            margin-top: ${props.theme.space.medium};
            margin-bottom: ${props.theme.space.medium};
        `}

		${breakpointUp.large`
            margin-top: ${props.theme.space.large};
            margin-bottom: ${props.theme.space.large};
        `}
	`}
`;

// --------------
// 2. Padding
// --------------
const paddingStyles = (props) => css`
	${props.mpad &&
	css`
		padding-left: ${props.theme.space.mpad};
		padding-right: ${props.theme.space.mpad};

		${breakpointUp.large`
            padding-left: 0px;
            padding-right: 0px;
        `}
	`}

	${props.mpadLarge &&
	css`
		padding-left: ${props.theme.space.mpad};
		padding-right: ${props.theme.space.mpad};
	`}

    ${props.padBottom &&
	css`
		padding-bottom: ${props.theme.space.small};

		${breakpointUp.medium` padding-bottom: ${props.theme.space.medium}; `}
		${breakpointUp.large` padding-bottom: ${props.theme.space.large}; `}
	`}

	${props.padBottomSmall &&
	css`
		padding-bottom: calc(${props.theme.space.small} /2);

		${breakpointUp.medium` padding-bottom: calc(${props.theme.space.medium} /2);`}
		${breakpointUp.large` padding-bottom: calc(${props.theme.space.large} /2);`}
	`}

    ${props.padTop &&
	css`
		padding-top: ${props.theme.space.small};

		${breakpointUp.medium` padding-top: ${props.theme.space.medium}; `}
		${breakpointUp.large` padding-top: ${props.theme.space.large}; `}
	`}
    
    ${props.pad &&
	css`
		padding-top: ${props.theme.space.small};
		padding-bottom: ${props.theme.space.small};

		${breakpointUp.medium`
            padding-top: ${props.theme.space.medium};
            padding-bottom: ${props.theme.space.medium};
        `}

		${breakpointUp.large`
            padding-top: ${props.theme.space.large};
            padding-bottom: ${props.theme.space.large};
        `}
	`}
`;

// --------------
// 7. Export: Container Styles
// --------------
export const semantics = css`
	${paddingStyles}
	${marginStyles}
`;
