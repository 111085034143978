// Imports
// ------------
import React, { useEffect, useState, useRef } from 'react';
import Logo from '@parts/Logo';
import Button from '@parts/Button';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import CustomForm from '@parts/CustomForm';
import { header } from '@states';
import { observer } from 'mobx-react-lite';
import { useDato } from './data';
import { mailList, mailU, mailId } from '@utils/mailchimp';

// Styles
// ------------
import {
	Jacket,
	Label,
	Text,
	Heading,
	EmailNotifications,
	Instagram,
	Clicker,
	Close,
	Animation,
} from './styles';

// Component
// ------------
const Header = () => {
	// NOTE • States
	const [isOpen, setIsOpen] = useState(false);

	// NOTE • Refs
	const main = useRef();

	// NOTE • Data
	const { email, insta, instaUrl } = useDato();

	// NOTE • Handler for menu
	const handleHeader = () => {
		setIsOpen(!isOpen);
	};

	// NOTE • On click of any element outside
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (main.current && !main.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	// NOTE • MailChimp
	const postUrl = `${mailList}?u=${mailU}&id=${mailId}`;

	return (
		<Jacket isOpen={isOpen} ref={main} hide={header.hide} hasInsta={insta}>
			<Clicker
				type='button'
				onClick={handleHeader}
				isOpen={isOpen}
				className='hover-trigger'>
				<Logo isOpen={isOpen} />

				<Close>
					<span />
					<span />
				</Close>

				<Label>Get in Touch</Label>
			</Clicker>

			<Animation isOpen={isOpen}>
				<Heading>
					The MONOSTATE e-commerce experience site will launch soon.
				</Heading>
				<EmailNotifications>
					<Label>Receive notifications about our launch</Label>
					<MailchimpSubscribe
						url={postUrl}
						render={({ subscribe, status, message }) => (
							<CustomForm
								headerClosed={!isOpen}
								status={status}
								message={message}
								onValidated={(formData) => subscribe(formData)}
							/>
						)}
					/>
				</EmailNotifications>

				{insta && (
					<Instagram>
						<Label>Follow our Instagram for updates</Label>
						<Button to={instaUrl}>Instagram</Button>
					</Instagram>
				)}

				<Text>
					To become a partner or for all other business inquiries, please
					contact{' '}
					<a href={`mailto:${email}`} className='hover-trigger'>
						{email}
					</a>
				</Text>
			</Animation>
		</Jacket>
	);
};

export default observer(Header);
