// Imports
// ------
import { css } from 'styled-components';
import { breakup } from '@tackl';

// Exports
// ------
const ordering = (props) => css`
	${props.order &&
	css`
		order: ${props.order};
	`}

	${props.orderSmall &&
	css`
		order: ${props.orderSmall};
	`}

    ${props.orderSmedium &&
	css`
		${breakup.smedium`
            order: ${props.orderSmedium};
        `}
	`}

    ${props.orderMedium &&
	css`
		${breakup.medium`
            order: ${props.orderMedium};
        `}
	`}

    ${props.orderLarge &&
	css`
		${breakup.large`
            order: ${props.orderLarge};
        `}
	`}

    ${props.orderXLarge &&
	css`
		${breakup.xlarge`
            order: ${props.orderXLarge};
        `}
	`}

    ${props.orderXXLarge &&
	css`
		${breakup.xxlarge`
            order: ${props.orderXXLarge};
        `}
	`}

    ${props.orderHuge &&
	css`
		${breakup.huge`
            order: ${props.orderHuge};
        `}
	`}

    ${props.orderUber &&
	css`
		${breakup.uber`
            order: ${props.orderUber};
        `}
	`}
`;

export default ordering;
